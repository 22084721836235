'use client';

import { createContext, useState } from 'react';
import type { PropsWithChildren } from 'react';
import { useCookie } from 'react-use';

import { parseJson } from '@/utils/string';

import {
  defaultDemoSettings,
  demoSettingsCookieName,
  getDemoSettingsCookieExpiration,
  type DemoSettings,
  type Environment,
} from './environment';

export const EnvironmentContext = createContext<
  | (Environment & {
      updateAuthToken: (token: string) => void;
      demoSettings: DemoSettings;
      updateDemoSettings: (settings: DemoSettings) => void;
    })
  | null
>(null);

export const EnvironmentProvider = ({
  environment,
  children,
}: PropsWithChildren<{
  environment: Environment;
}>) => {
  const [env, setEnv] = useState(environment);
  const [value, updateCookie] = useCookie(demoSettingsCookieName);
  const [demoSettings, setDemoSettings] = useState(
    parseJson<DemoSettings>(value) || defaultDemoSettings
  );

  return (
    <EnvironmentContext.Provider
      value={{
        ...env,
        updateAuthToken: (token) => {
          setEnv({ ...env, authToken: `Bearer ${token}` });
        },
        demoSettings,
        updateDemoSettings: (settings) => {
          setDemoSettings(settings);
          updateCookie(JSON.stringify(settings), {
            expires: getDemoSettingsCookieExpiration(),
          });
        },
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};
