/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class AzureOnboardingStatus {
  /**
  * The management group or subscription ID
  */
  'entityId': string;
  /**
  * The entity type
  */
  'entityType': string;
  /**
  * The entity name
  */
  'entityName'?: string;
  /**
  * The source account ID
  */
  'sourceAccountId'?: string;
  /**
  * The subscription status
  */
  'status': string;
  /**
  * The subscription status message
  */
  'message'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "entityId",
      "baseName": "entityId",
      "type": "string",
      "format": ""
    },
    {
      "name": "entityType",
      "baseName": "entityType",
      "type": "string",
      "format": ""
    },
    {
      "name": "entityName",
      "baseName": "entityName",
      "type": "string",
      "format": ""
    },
    {
      "name": "sourceAccountId",
      "baseName": "sourceAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "string",
      "format": ""
    },
    {
      "name": "message",
      "baseName": "message",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AzureOnboardingStatus.attributeTypeMap;
  }

  public constructor() {
  }
}

