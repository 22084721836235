/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class CloudResourceSecurityProperties {
  /**
  * The Resource\'s Eon-assigned ID.
  */
  'resourceId': string;
  /**
  * should mute the alerts or not on the resource
  */
  'muteAlerts': boolean;
  /**
  * Date and time the scan was ended.
  */
  'updatedTime'?: Date;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "resourceId",
      "baseName": "resourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "muteAlerts",
      "baseName": "muteAlerts",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "updatedTime",
      "baseName": "updatedTime",
      "type": "Date",
      "format": "date-time"
    }  ];

  static getAttributeTypeMap() {
    return CloudResourceSecurityProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

