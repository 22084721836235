/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { InventorySnapshotVolume } from '../models/InventorySnapshotVolume';

/**
* Properties of the original AWS EC2 instance at the time the snapshot was taken. Present only when `resourceType` is set to `AWS_EC2`. 
*/
export class AwsEc2SnapshotProperties {
  /**
  * Instance type.
  */
  'instanceType'?: string;
  /**
  * ID of the subnet the instance is in.
  */
  'subnetId'?: string;
  /**
  * IDs of security groups associated with the resource.
  */
  'securityGroupIds'?: Array<string>;
  /**
  * The resource\'s instance profile.
  */
  'instanceProfileName'?: string;
  'volumes'?: Array<InventorySnapshotVolume>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "instanceType",
      "baseName": "instanceType",
      "type": "string",
      "format": ""
    },
    {
      "name": "subnetId",
      "baseName": "subnetId",
      "type": "string",
      "format": ""
    },
    {
      "name": "securityGroupIds",
      "baseName": "securityGroupIds",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "instanceProfileName",
      "baseName": "instanceProfileName",
      "type": "string",
      "format": ""
    },
    {
      "name": "volumes",
      "baseName": "volumes",
      "type": "Array<InventorySnapshotVolume>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AwsEc2SnapshotProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

