/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { VolumeSettings } from '../models/VolumeSettings';

export class InventorySnapshotVolume {
  /**
  * The ID of the volume
  */
  'providerVolumeId': string;
  /**
  * The region of the volume
  */
  'region': string;
  /**
  * The availability zone of the volume
  */
  'availabilityZone': string;
  /**
  * The tags associated with the resource
  */
  'tags': { [key: string]: string; };
  /**
  * The allocated size of the volume in bytes
  */
  'allocatedSize'?: number;
  'volumeSettings': VolumeSettings;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "providerVolumeId",
      "baseName": "providerVolumeId",
      "type": "string",
      "format": ""
    },
    {
      "name": "region",
      "baseName": "region",
      "type": "string",
      "format": ""
    },
    {
      "name": "availabilityZone",
      "baseName": "availabilityZone",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "allocatedSize",
      "baseName": "allocatedSize",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "volumeSettings",
      "baseName": "volumeSettings",
      "type": "VolumeSettings",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return InventorySnapshotVolume.attributeTypeMap;
  }

  public constructor() {
  }
}

