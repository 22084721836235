/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AzureVmDisk } from '../models/AzureVmDisk';

/**
* Properties of the original Azure Virtual Machine instance at the time the snapshot was taken. Present only when `resourceType` is set to `AZURE_VIRTUAL_MACHINE`. 
*/
export class AzureVmSnapshotProperties {
  /**
  * The name of the resource
  */
  'name'?: string;
  /**
  * The size of the virtual machine
  */
  'sizeBytes'?: string;
  'disks'?: Array<AzureVmDisk>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "sizeBytes",
      "baseName": "sizeBytes",
      "type": "string",
      "format": ""
    },
    {
      "name": "disks",
      "baseName": "disks",
      "type": "Array<AzureVmDisk>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AzureVmSnapshotProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

