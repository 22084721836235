/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SnapshotLocation } from '../models/SnapshotLocation';

export class ConvertOtherSnapshotsOnDemandRequestSnapshotDetailsInner {
  'snapshotLocation': SnapshotLocation;
  /**
  * The provider ID of the volume
  */
  'providerVolumeId': string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "snapshotLocation",
      "baseName": "snapshotLocation",
      "type": "SnapshotLocation",
      "format": ""
    },
    {
      "name": "providerVolumeId",
      "baseName": "providerVolumeId",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ConvertOtherSnapshotsOnDemandRequestSnapshotDetailsInner.attributeTypeMap;
  }

  public constructor() {
  }
}

