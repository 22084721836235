/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class DiscoveryJob {
  /**
  * The run ID of the discovery workflow
  */
  'runId': string;
  /**
  * The date the discovery process started
  */
  'startedAt': Date;
  /**
  * The date the discovery process finished
  */
  'finishedAt': Date;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "runId",
      "baseName": "runId",
      "type": "string",
      "format": "uuid"
    },
    {
      "name": "startedAt",
      "baseName": "startedAt",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "finishedAt",
      "baseName": "finishedAt",
      "type": "Date",
      "format": "date-time"
    }  ];

  static getAttributeTypeMap() {
    return DiscoveryJob.attributeTypeMap;
  }

  public constructor() {
  }
}

