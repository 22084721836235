/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Cloud provider that hosts the resource.
*/
export enum Provider {
  Aws = 'AWS',
  Azure = 'AZURE',
  Gcp = 'GCP',
  MongoAtlas = 'MONGO_ATLAS',
  ProviderUnspecified = 'PROVIDER_UNSPECIFIED'
}
