/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AzureOnboardingStatus } from '../models/AzureOnboardingStatus';

export class AzureOnboardingStatusResponse {
  'entities': Array<AzureOnboardingStatus>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "entities",
      "baseName": "entities",
      "type": "Array<AzureOnboardingStatus>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AzureOnboardingStatusResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

