/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { BackupPolicy } from '../models/BackupPolicy';
import { BackupStatus } from '../models/BackupStatus';
import { Classifications } from '../models/Classifications';
import { ControlViolations } from '../models/ControlViolations';
import { InventoryResourceMetadata } from '../models/InventoryResourceMetadata';
import { InventoryVault } from '../models/InventoryVault';
import { Provider } from '../models/Provider';
import { ResourceProperties } from '../models/ResourceProperties';
import { ResourceType } from '../models/ResourceType';
import { SecurityScan } from '../models/SecurityScan';
import { SnapshotStorage } from '../models/SnapshotStorage';
import { SourceStorage } from '../models/SourceStorage';

export class InventoryResource {
  /**
  * Eon-assigned resource ID.
  */
  'id': string;
  /**
  * Date and time the resource record was created in Eon.
  */
  'createdTime'?: Date;
  /**
  * Date and time the resource was first discovered.
  */
  'discoveredTime'?: Date;
  /**
  * Date and time of the resource\'s latest Eon snapshot.
  */
  'latestSnapshotTime'?: Date;
  /**
  * Date and time of the resource\'s first Eon snapshot.
  */
  'oldestSnapshotTime'?: Date;
  'backupStatus': BackupStatus;
  /**
  * Cloud-provider-assigned resource ID.
  */
  'providerResourceId': string;
  /**
  * Resource display name.
  */
  'resourceName': string;
  'classifications'?: Classifications;
  /**
  * The account ID of the inventory item
  */
  'providerAccountId': string;
  'snapshotStorage': SnapshotStorage;
  'sourceStorage': SourceStorage;
  'controlViolationCounts'?: ControlViolations;
  /**
  * Resource tags as key-value pairs. Both keys and values are strings. If a tag is a key with no value, the value is presented as an empty string. 
  */
  'tags': { [key: string]: string; };
  'cloudProvider': Provider;
  'resourceType': ResourceType;
  /**
  * The source region of the inventory item
  */
  'region'?: string;
  /**
  * VPC the resource is in.
  */
  'vpc'?: string;
  /**
  * List of subnets the resource belongs to.
  */
  'subnets'?: Array<string>;
  'resourceProperties'?: ResourceProperties | null;
  /**
  * The backup policy names of the inventory item
  */
  'backupPolicies': Array<BackupPolicy>;
  /**
  * The vault names of the inventory item
  */
  'vaults': Array<InventoryVault>;
  'metadata'?: InventoryResourceMetadata;
  'securityScan'?: SecurityScan | null;
  /**
  * Resource group ID.
  */
  'resourceGroupId'?: string;
  /**
  * Resource group name.
  */
  'resourceGroupName'?: string;
  /**
  * The cluster name of the inventory item
  */
  'clusterName'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "createdTime",
      "baseName": "createdTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "discoveredTime",
      "baseName": "discoveredTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "latestSnapshotTime",
      "baseName": "latestSnapshotTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "oldestSnapshotTime",
      "baseName": "oldestSnapshotTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "backupStatus",
      "baseName": "backupStatus",
      "type": "BackupStatus",
      "format": ""
    },
    {
      "name": "providerResourceId",
      "baseName": "providerResourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceName",
      "baseName": "resourceName",
      "type": "string",
      "format": ""
    },
    {
      "name": "classifications",
      "baseName": "classifications",
      "type": "Classifications",
      "format": ""
    },
    {
      "name": "providerAccountId",
      "baseName": "providerAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "snapshotStorage",
      "baseName": "snapshotStorage",
      "type": "SnapshotStorage",
      "format": ""
    },
    {
      "name": "sourceStorage",
      "baseName": "sourceStorage",
      "type": "SourceStorage",
      "format": ""
    },
    {
      "name": "controlViolationCounts",
      "baseName": "controlViolationCounts",
      "type": "ControlViolations",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "cloudProvider",
      "baseName": "cloudProvider",
      "type": "Provider",
      "format": ""
    },
    {
      "name": "resourceType",
      "baseName": "resourceType",
      "type": "ResourceType",
      "format": ""
    },
    {
      "name": "region",
      "baseName": "region",
      "type": "string",
      "format": ""
    },
    {
      "name": "vpc",
      "baseName": "vpc",
      "type": "string",
      "format": ""
    },
    {
      "name": "subnets",
      "baseName": "subnets",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "resourceProperties",
      "baseName": "resourceProperties",
      "type": "ResourceProperties",
      "format": ""
    },
    {
      "name": "backupPolicies",
      "baseName": "backupPolicies",
      "type": "Array<BackupPolicy>",
      "format": ""
    },
    {
      "name": "vaults",
      "baseName": "vaults",
      "type": "Array<InventoryVault>",
      "format": ""
    },
    {
      "name": "metadata",
      "baseName": "metadata",
      "type": "InventoryResourceMetadata",
      "format": ""
    },
    {
      "name": "securityScan",
      "baseName": "securityScan",
      "type": "SecurityScan",
      "format": ""
    },
    {
      "name": "resourceGroupId",
      "baseName": "resourceGroupId",
      "type": "string",
      "format": ""
    },
    {
      "name": "resourceGroupName",
      "baseName": "resourceGroupName",
      "type": "string",
      "format": ""
    },
    {
      "name": "clusterName",
      "baseName": "clusterName",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return InventoryResource.attributeTypeMap;
  }

  public constructor() {
  }
}



