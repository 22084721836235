/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { EksCluster } from '../models/EksCluster';
import { Pagination } from '../models/Pagination';

export class ListEksClustersResponse {
  'eksClusters'?: Array<EksCluster>;
  'pagination': Pagination;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "eksClusters",
      "baseName": "eksClusters",
      "type": "Array<EksCluster>",
      "format": ""
    },
    {
      "name": "pagination",
      "baseName": "pagination",
      "type": "Pagination",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ListEksClustersResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

