import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { S3ExclusionRule } from '../models/S3ExclusionRule';

/**
 * no description
 */
export class CreateS3ExclusionRuleApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Create s3 exclusion rule
   * @param projectId The project ID
   * @param s3ExclusionRule The request body for creating a exclusion rule
   */
  public async createS3ExclusionRule(projectId: string, s3ExclusionRule: S3ExclusionRule, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("CreateS3ExclusionRuleApi", "createS3ExclusionRule", "projectId");
    }


    // verify required parameter 's3ExclusionRule' is not null or undefined
    if (s3ExclusionRule === null || s3ExclusionRule === undefined) {
      throw new RequiredError("CreateS3ExclusionRuleApi", "createS3ExclusionRule", "s3ExclusionRule");
    }


    // Path Params
    const localVarPath = '/projects/{projectId}/s3-exclusion-rules'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(s3ExclusionRule, "S3ExclusionRule", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class CreateS3ExclusionRuleApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to createS3ExclusionRule
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async createS3ExclusionRuleWithHttpInfo(response: ResponseContext): Promise<HttpInfo<S3ExclusionRule >> {
    if (isCodeInRange("201", response.httpStatusCode)) {
      const body: S3ExclusionRule = ObjectSerializer.deserialize(
        await response.body.json(),
        "S3ExclusionRule", ""
      ) as S3ExclusionRule;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: S3ExclusionRule = await response.body.json() as S3ExclusionRule;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
