import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { ListAuditLogRequest } from '../models/ListAuditLogRequest';
import { ListAuditLogResponse } from '../models/ListAuditLogResponse';

/**
 * no description
 */
export class AuditLogsApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * List Audit Logs
   * @param pageToken Cursor that points to the first record of the next page of results. Get this value from the previous response. To preserve the results in the same order, use the same sorting and filters in the first request as all subsequent requests. 
   * @param pageSize Maximum number of items to return in the response.
   * @param listAuditLogRequest The request body for filtering audit logs
   */
  public async listAuditLogs(pageToken?: string, pageSize?: number, listAuditLogRequest?: ListAuditLogRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    





    // Path Params
    const localVarPath = '/v1/audit-logs';

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (pageToken !== undefined) {
      requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
    }

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(listAuditLogRequest, "ListAuditLogRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class AuditLogsApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to listAuditLogs
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async listAuditLogsWithHttpInfo(response: ResponseContext): Promise<HttpInfo<ListAuditLogResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: ListAuditLogResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "ListAuditLogResponse", ""
      ) as ListAuditLogResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: ListAuditLogResponse = await response.body.json() as ListAuditLogResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
