/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Details about backup snapshot storage.
*/
export class SnapshotStorage {
  /**
  * Total size of the resource\'s Eon snapshots, in bytes.
  */
  'sizeBytes'?: number;
  /**
  * Number of Eon snapshots backing up the resource.
  */
  'eonSnapshotCount'?: number;
  /**
  * Number of non-Eon snapshots backing up the resource. Only cloud-provider-native snapshots in the source account are included in this count. 
  */
  'nonEonSnapshotCount'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "sizeBytes",
      "baseName": "sizeBytes",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "eonSnapshotCount",
      "baseName": "eonSnapshotCount",
      "type": "number",
      "format": ""
    },
    {
      "name": "nonEonSnapshotCount",
      "baseName": "nonEonSnapshotCount",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SnapshotStorage.attributeTypeMap;
  }

  public constructor() {
  }
}

