/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { KubernetesAccessMode } from '../models/KubernetesAccessMode';
import { VolumeSettings } from '../models/VolumeSettings';

export class PersistentVolumeClaimProperties {
  /**
  * The volume\'s cloud-provider-assigned resource ID of the persistent volume claim.
  */
  'providerResourceId': string;
  /**
  * Persistent volume claim path on the machine it\'s attached to.
  */
  'path': string;
  /**
  * Volume availability zone.
  */
  'availabilityZone': string;
  'volumeSettings': VolumeSettings;
  /**
  * File system type of the persistent volume claim.
  */
  'fsType'?: string;
  /**
  * The name of the persistent volume.
  */
  'pvName': string;
  /**
  * The name of the persistent volume claim.
  */
  'pvcName': string;
  'accessModes'?: Array<KubernetesAccessMode>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "providerResourceId",
      "baseName": "providerResourceId",
      "type": "string",
      "format": ""
    },
    {
      "name": "path",
      "baseName": "path",
      "type": "string",
      "format": ""
    },
    {
      "name": "availabilityZone",
      "baseName": "availabilityZone",
      "type": "string",
      "format": ""
    },
    {
      "name": "volumeSettings",
      "baseName": "volumeSettings",
      "type": "VolumeSettings",
      "format": ""
    },
    {
      "name": "fsType",
      "baseName": "fsType",
      "type": "string",
      "format": ""
    },
    {
      "name": "pvName",
      "baseName": "pvName",
      "type": "string",
      "format": ""
    },
    {
      "name": "pvcName",
      "baseName": "pvcName",
      "type": "string",
      "format": ""
    },
    {
      "name": "accessModes",
      "baseName": "accessModes",
      "type": "Array<KubernetesAccessMode>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return PersistentVolumeClaimProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

