/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { InventoryVolume } from '../models/InventoryVolume';

/**
* Properties of the original AWS EC2 instance. Present only when `resourceType` is set to `AWS_EC2`. 
*/
export class AwsEc2Resource {
  /**
  * List of volumes attached to the resource.
  */
  'volumes'?: Array<InventoryVolume>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "volumes",
      "baseName": "volumes",
      "type": "Array<InventoryVolume>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AwsEc2Resource.attributeTypeMap;
  }

  public constructor() {
  }
}

