/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { PersistentVolumeClaimProperties } from '../models/PersistentVolumeClaimProperties';

export class AwsEksNamespaceResource {
  /**
  * The cluster Name of the namespace.
  */
  'clusterName': string;
  /**
  * The name of the eks namespace.
  */
  'name': string;
  /**
  * The status of the namespace.
  */
  'status': string;
  /**
  * The creation time of the namespace.
  */
  'creationTime': Date;
  'persistentVolumeClaims'?: Array<PersistentVolumeClaimProperties>;
  /**
  * The number of secrets in the namespace.
  */
  'secretsCount'?: number;
  'labels'?: { [key: string]: string; };
  'annotations'?: { [key: string]: string; };
  /**
  * The resource version of the namespace.
  */
  'resourceVersion'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "clusterName",
      "baseName": "clusterName",
      "type": "string",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "string",
      "format": ""
    },
    {
      "name": "creationTime",
      "baseName": "creationTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "persistentVolumeClaims",
      "baseName": "persistentVolumeClaims",
      "type": "Array<PersistentVolumeClaimProperties>",
      "format": ""
    },
    {
      "name": "secretsCount",
      "baseName": "secretsCount",
      "type": "number",
      "format": ""
    },
    {
      "name": "labels",
      "baseName": "labels",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "annotations",
      "baseName": "annotations",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "resourceVersion",
      "baseName": "resourceVersion",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AwsEksNamespaceResource.attributeTypeMap;
  }

  public constructor() {
  }
}

