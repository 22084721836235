/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AwsEc2Resource } from '../models/AwsEc2Resource';
import { AwsEksNamespaceResource } from '../models/AwsEksNamespaceResource';
import { AwsRdsResource } from '../models/AwsRdsResource';

/**
* Source resource properties. The properties present in this object change depending on the value set in `resourceType`. 
*/
export class ResourceProperties {
  'awsEc2'?: AwsEc2Resource | null;
  'awsRds'?: AwsRdsResource | null;
  'awsEksNamespace'?: AwsEksNamespaceResource | null;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "awsEc2",
      "baseName": "awsEc2",
      "type": "AwsEc2Resource",
      "format": ""
    },
    {
      "name": "awsRds",
      "baseName": "awsRds",
      "type": "AwsRdsResource",
      "format": ""
    },
    {
      "name": "awsEksNamespace",
      "baseName": "awsEksNamespace",
      "type": "AwsEksNamespaceResource",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ResourceProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

