/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { SecurityScan } from '../models/SecurityScan';

export class SecurityScansSummaryResponse {
  'firstInfected'?: SecurityScan | null;
  'lastScan'?: SecurityScan | null;
  'lastClean'?: SecurityScan | null;
  /**
  * The number of infected snapshots of that resource
  */
  'infectedSnapshotsCount'?: number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "firstInfected",
      "baseName": "firstInfected",
      "type": "SecurityScan",
      "format": ""
    },
    {
      "name": "lastScan",
      "baseName": "lastScan",
      "type": "SecurityScan",
      "format": ""
    },
    {
      "name": "lastClean",
      "baseName": "lastClean",
      "type": "SecurityScan",
      "format": ""
    },
    {
      "name": "infectedSnapshotsCount",
      "baseName": "infectedSnapshotsCount",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SecurityScansSummaryResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

