/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AzureSubscription } from '../models/AzureSubscription';

export class AzureManagementGroup {
  /**
  * The ID of the tenant
  */
  'tenantId'?: string;
  /**
  * The ID of the management group
  */
  'id': string;
  /**
  * The name of the management group
  */
  'name': string;
  /**
  * The state of the management group
  */
  'state'?: string;
  'subscriptions'?: Array<AzureSubscription>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "tenantId",
      "baseName": "tenantId",
      "type": "string",
      "format": ""
    },
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "state",
      "baseName": "state",
      "type": "string",
      "format": ""
    },
    {
      "name": "subscriptions",
      "baseName": "subscriptions",
      "type": "Array<AzureSubscription>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AzureManagementGroup.attributeTypeMap;
  }

  public constructor() {
  }
}

