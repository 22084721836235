/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { RestoreEksNamespaceVolumeInput } from '../models/RestoreEksNamespaceVolumeInput';
import { S3RestoreTarget } from '../models/S3RestoreTarget';

export class RestoreEksNamespaceInput {
  /**
  * Optional tags to apply to the output volume
  */
  'tags'?: { [key: string]: string; };
  /**
  * The ID of the restore account (target)
  */
  'restoreAccountId': string;
  /**
  * The region to restore the instance to
  */
  'region': string;
  'filesTarget': S3RestoreTarget;
  'volumeSettings': Array<RestoreEksNamespaceVolumeInput>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "restoreAccountId",
      "baseName": "restoreAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "region",
      "baseName": "region",
      "type": "string",
      "format": ""
    },
    {
      "name": "filesTarget",
      "baseName": "filesTarget",
      "type": "S3RestoreTarget",
      "format": ""
    },
    {
      "name": "volumeSettings",
      "baseName": "volumeSettings",
      "type": "Array<RestoreEksNamespaceVolumeInput>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreEksNamespaceInput.attributeTypeMap;
  }

  public constructor() {
  }
}

