/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class S3ExclusionRule {
  /**
  * The id of the exclusion rule
  */
  'id': string;
  /**
  * The name of the bucket
  */
  'bucket': string;
  /**
  * exclude objects modified before this date
  */
  'modificationDate'?: Date | null;
  /**
  * exclude objects starting with this prefix
  */
  'bucketPrefix'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "bucket",
      "baseName": "bucket",
      "type": "string",
      "format": ""
    },
    {
      "name": "modificationDate",
      "baseName": "modificationDate",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "bucketPrefix",
      "baseName": "bucketPrefix",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return S3ExclusionRule.attributeTypeMap;
  }

  public constructor() {
  }
}

