/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class SourceAccountConfigurationUpdateInput {
  /**
  * Whether this account supports CDC backup
  */
  'enableCdcBackup'?: boolean | null;
  /**
  * Whether this account supports S3 notification management
  */
  'enableS3NotificationManagement'?: boolean | null;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "enableCdcBackup",
      "baseName": "enableCdcBackup",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "enableS3NotificationManagement",
      "baseName": "enableS3NotificationManagement",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SourceAccountConfigurationUpdateInput.attributeTypeMap;
  }

  public constructor() {
  }
}

