/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { DiscoveryJob } from '../models/DiscoveryJob';

export class DiscoveryStatusResponse {
  'lastJob': DiscoveryJob;
  'lastFinishedJob': DiscoveryJob;
  /**
  * Whether any discovery process has finished
  */
  'finished': boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "lastJob",
      "baseName": "lastJob",
      "type": "DiscoveryJob",
      "format": ""
    },
    {
      "name": "lastFinishedJob",
      "baseName": "lastFinishedJob",
      "type": "DiscoveryJob",
      "format": ""
    },
    {
      "name": "finished",
      "baseName": "finished",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return DiscoveryStatusResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

