/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* The current status of the cluster
*/
export enum EksClusterStatus {
  EKS_CLUSTER_STATUS_UNSPECIFIED = 'EKS_CLUSTER_STATUS_UNSPECIFIED',
  EKS_CLUSTER_STATUS_CREATING = 'EKS_CLUSTER_STATUS_CREATING',
  EKS_CLUSTER_STATUS_ACTIVE = 'EKS_CLUSTER_STATUS_ACTIVE',
  EKS_CLUSTER_STATUS_DELETING = 'EKS_CLUSTER_STATUS_DELETING',
  EKS_CLUSTER_STATUS_FAILED = 'EKS_CLUSTER_STATUS_FAILED',
  EKS_CLUSTER_STATUS_UPDATING = 'EKS_CLUSTER_STATUS_UPDATING',
  EKS_CLUSTER_STATUS_PENDING = 'EKS_CLUSTER_STATUS_PENDING'
}
