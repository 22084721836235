import { BaseAPIRequestFactory, RequiredError } from './baseapi';
import type { Configuration } from '../configuration';
import { RequestContext, HttpMethod, ResponseContext, HttpInfo } from '../http/http';
import { ObjectSerializer } from '../models/ObjectSerializer';
import { ApiException } from './exception';
import { isCodeInRange } from '../util';
import type {SecurityAuthentication} from '../auth/auth';


import { QueryDBRequest } from '../models/QueryDBRequest';
import { QueryDBResponse } from '../models/QueryDBResponse';
import { QueryDBResultResponse } from '../models/QueryDBResultResponse';
import { QueryDBStatusResponse } from '../models/QueryDBStatusResponse';

/**
 * no description
 */
export class DatabaseSnapshotsApiRequestFactory extends BaseAPIRequestFactory {

  /**
   * Get Query Result
   * @param projectId ID of the [project](/global-settings/projects) that contains the query you want to retrieve. 
   * @param queryId Query ID returned by [Run Query](./run-query).
   * @param pageSize Maximum number of items to return in the response.
   * @param pageToken Cursor that points to the first record of the next page of results. Get this value from the previous response. 
   */
  public async getQueryResult(projectId: string, queryId: string, pageSize?: number, pageToken?: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("DatabaseSnapshotsApi", "getQueryResult", "projectId");
    }


    // verify required parameter 'queryId' is not null or undefined
    if (queryId === null || queryId === undefined) {
      throw new RequiredError("DatabaseSnapshotsApi", "getQueryResult", "queryId");
    }




    // Path Params
    const localVarPath = '/v1/projects/{projectId}/queries/{queryId}/results'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'queryId' + '}', encodeURIComponent(String(queryId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")

    // Query Params
    if (pageSize !== undefined) {
      requestContext.setQueryParam("pageSize", ObjectSerializer.serialize(pageSize, "number", ""));
    }

    // Query Params
    if (pageToken !== undefined) {
      requestContext.setQueryParam("pageToken", ObjectSerializer.serialize(pageToken, "string", ""));
    }


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Get Query Status
   * @param projectId ID of the [project](/global-settings/projects) that contains the query status you want to retrieve. 
   * @param queryId Query ID returned by [Run Query](./run-query).
   */
  public async getQueryStatus(projectId: string, queryId: string, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("DatabaseSnapshotsApi", "getQueryStatus", "projectId");
    }


    // verify required parameter 'queryId' is not null or undefined
    if (queryId === null || queryId === undefined) {
      throw new RequiredError("DatabaseSnapshotsApi", "getQueryStatus", "queryId");
    }


    // Path Params
    const localVarPath = '/v1/projects/{projectId}/queries/{queryId}/status'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'queryId' + '}', encodeURIComponent(String(queryId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.GET, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

  /**
   * Run Query
   * @param projectId ID of the [project](/global-settings/projects) that contains the database snapshot. 
   * @param snapshotId ID of the database [snapshot](./list-resource-snapshots) you want to query. Snapshot must belong to an RDS resource. 
   * @param queryDBRequest 
   */
  public async runQuery(projectId: string, snapshotId: string, queryDBRequest?: QueryDBRequest, _options?: Configuration): Promise<RequestContext> {
    let _config;
    if (!_options) {
      _config = this.configuration;
    } else {
      _config = Object.assign({}, _options, this.configuration);
    }
    


    // verify required parameter 'projectId' is not null or undefined
    if (projectId === null || projectId === undefined) {
      throw new RequiredError("DatabaseSnapshotsApi", "runQuery", "projectId");
    }


    // verify required parameter 'snapshotId' is not null or undefined
    if (snapshotId === null || snapshotId === undefined) {
      throw new RequiredError("DatabaseSnapshotsApi", "runQuery", "snapshotId");
    }



    // Path Params
    const localVarPath = '/v1/projects/{projectId}/snapshots/{snapshotId}/databases/query'
      .replace('{' + 'projectId' + '}', encodeURIComponent(String(projectId)))
      .replace('{' + 'snapshotId' + '}', encodeURIComponent(String(snapshotId)));

    let signal = _config.timeout ? AbortSignal.timeout(_config.timeout) : undefined;
    // Make Request Context
    const requestContext = _config.baseServer!.makeRequestContext(localVarPath, HttpMethod.POST, signal);
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8")


    // Body Params
    const contentType = "application/json"
    requestContext.setHeaderParam("Content-Type", contentType);
    const serializedBody = JSON.stringify(ObjectSerializer.serialize(queryDBRequest, "QueryDBRequest", ""));
    requestContext.setBody(serializedBody);

    let authMethod: SecurityAuthentication | undefined;
    // Apply auth methods
    authMethod = _config.authMethods!["ApiKeyAuth"]
    if (authMethod?.applySecurityAuthentication) {
      await authMethod?.applySecurityAuthentication(requestContext);
    }

    return requestContext;
  }

}

export class DatabaseSnapshotsApiResponseProcessor {

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to getQueryResult
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async getQueryResultWithHttpInfo(response: ResponseContext): Promise<HttpInfo<QueryDBResultResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: QueryDBResultResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "QueryDBResultResponse", ""
      ) as QueryDBResultResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Query/Token not found", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: QueryDBResultResponse = await response.body.json() as QueryDBResultResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to getQueryStatus
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async getQueryStatusWithHttpInfo(response: ResponseContext): Promise<HttpInfo<QueryDBStatusResponse >> {
    if (isCodeInRange("200", response.httpStatusCode)) {
      const body: QueryDBStatusResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "QueryDBStatusResponse", ""
      ) as QueryDBStatusResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("404", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Query wasn\&#39;t found.", undefined, response.headers, response);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: QueryDBStatusResponse = await response.body.json() as QueryDBStatusResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

  /**
   * Unwraps the actual response sent by the server from the response context and deserializes the response content
   * to the expected objects
   *
   * @params response Response returned by the server for a request to runQuery
   * @throws ApiException if the response code was not in [200, 299]
   */
   public async runQueryWithHttpInfo(response: ResponseContext): Promise<HttpInfo<QueryDBResponse >> {
    if (isCodeInRange("202", response.httpStatusCode)) {
      const body: QueryDBResponse = ObjectSerializer.deserialize(
        await response.body.json(),
        "QueryDBResponse", ""
      ) as QueryDBResponse;
      return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
    }
    if (isCodeInRange("1XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Informational", undefined, response.headers, response);
    }
    if (isCodeInRange("3XX", response.httpStatusCode)) {
      throw new ApiException<undefined>(response.httpStatusCode, "Redirect", undefined, response.headers, response);
    }
    if (isCodeInRange("4XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Client Error", body, response.headers, response);
    }
    if (isCodeInRange("5XX", response.httpStatusCode)) {
      const body: Error = ObjectSerializer.deserialize(
        await response.body.json(),
        "Error", ""
      ) as Error;
      throw new ApiException<Error>(response.httpStatusCode, "Internal Server Error", body, response.headers, response);
    }

    // Work around for missing responses in specification, e.g. for petstore.yaml
    if (response.httpStatusCode > 200 || response.httpStatusCode > 299) {
      throw new ApiException<string | Blob | undefined>(response.httpStatusCode, "Unknown API Status Code!", await response.getBodyAsAny(), response.headers, response);
    }
    const body: QueryDBResponse = await response.body.json() as QueryDBResponse;
    return new HttpInfo(response.httpStatusCode, response.headers, response.body, body);
  }

}
