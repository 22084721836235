/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class SourceAccountConfiguration {
  /**
  * The ID of the cloud account
  */
  'cloudAccountId': string;
  /**
  * The ID of the Eon account
  */
  'eonAccountId': string;
  /**
  * Whether CDC backup is enabled
  */
  'enableCdcBackup': boolean;
  /**
  * Whether S3 notification management is enabled
  */
  'enableS3NotificationManagement': boolean;
  /**
  * Whether scanning retry is allowed
  */
  'allowScanningRetry': boolean;
  /**
  * The maximum number of scanning retries
  */
  'maxScanningRetries': number;
  /**
  * The age of resources that should be scanned again even if they exceed max retries (in nanoseconds)
  */
  'scanningRetryNewResourceAge': number;
  /**
  * Whether on-demand backup limits are ignored
  */
  'ignoreOnDemandBackupsLimit': boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "cloudAccountId",
      "baseName": "cloudAccountId",
      "type": "string",
      "format": "uuid"
    },
    {
      "name": "eonAccountId",
      "baseName": "eonAccountId",
      "type": "string",
      "format": "uuid"
    },
    {
      "name": "enableCdcBackup",
      "baseName": "enableCdcBackup",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "enableS3NotificationManagement",
      "baseName": "enableS3NotificationManagement",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "allowScanningRetry",
      "baseName": "allowScanningRetry",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "maxScanningRetries",
      "baseName": "maxScanningRetries",
      "type": "number",
      "format": ""
    },
    {
      "name": "scanningRetryNewResourceAge",
      "baseName": "scanningRetryNewResourceAge",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "ignoreOnDemandBackupsLimit",
      "baseName": "ignoreOnDemandBackupsLimit",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SourceAccountConfiguration.attributeTypeMap;
  }

  public constructor() {
  }
}

