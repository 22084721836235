/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { VolumeSettings } from '../models/VolumeSettings';

export class InventoryVolume {
  /**
  * The volume\'s cloud-provider-assigned resource ID.
  */
  'providerVolumeId': string;
  /**
  * Path of the volume on the machine it\'s attached to.
  */
  'path': string;
  /**
  * Region the volume is hosted in.
  */
  'region': string;
  /**
  * ID of the encryption key used to encrypt the volume.
  */
  'encryptionKeyId'?: string;
  /**
  * Volume tags as key-value pairs. Both keys and values are strings. If a tag is a key with no value, the value is presented as an empty string. 
  */
  'tags': { [key: string]: string; };
  /**
  * Volume availability zone.
  */
  'availabilityZone': string;
  'volumeSettings': VolumeSettings;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "providerVolumeId",
      "baseName": "providerVolumeId",
      "type": "string",
      "format": ""
    },
    {
      "name": "path",
      "baseName": "path",
      "type": "string",
      "format": ""
    },
    {
      "name": "region",
      "baseName": "region",
      "type": "string",
      "format": ""
    },
    {
      "name": "encryptionKeyId",
      "baseName": "encryptionKeyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "availabilityZone",
      "baseName": "availabilityZone",
      "type": "string",
      "format": ""
    },
    {
      "name": "volumeSettings",
      "baseName": "volumeSettings",
      "type": "VolumeSettings",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return InventoryVolume.attributeTypeMap;
  }

  public constructor() {
  }
}

