/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { EksClusterStatus } from '../models/EksClusterStatus';
import { KubeAccessStatus } from '../models/KubeAccessStatus';

export class EksCluster {
  /**
  * Eon-assigned cluster ID.
  */
  'id': string;
  /**
  * Aws account ID.
  */
  'providerAccountId': string;
  /**
  * The Amazon Resource Name (ARN) of the cluster.
  */
  'clusterArn': string;
  /**
  * The name of the cluster.
  */
  'clusterName': string;
  /**
  * The region of the cluster.
  */
  'region': string;
  /**
  * The endpoint of the cluster.
  */
  'endpoint': string;
  'status': EksClusterStatus;
  'kubeAccessStatus': KubeAccessStatus;
  /**
  * The encryption status of the cluster.
  */
  'encryptionEnabled'?: boolean;
  /**
  * The tags of the cluster.
  */
  'tags'?: { [key: string]: string; };

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "providerAccountId",
      "baseName": "providerAccountId",
      "type": "string",
      "format": ""
    },
    {
      "name": "clusterArn",
      "baseName": "clusterArn",
      "type": "string",
      "format": ""
    },
    {
      "name": "clusterName",
      "baseName": "clusterName",
      "type": "string",
      "format": ""
    },
    {
      "name": "region",
      "baseName": "region",
      "type": "string",
      "format": ""
    },
    {
      "name": "endpoint",
      "baseName": "endpoint",
      "type": "string",
      "format": ""
    },
    {
      "name": "status",
      "baseName": "status",
      "type": "EksClusterStatus",
      "format": ""
    },
    {
      "name": "kubeAccessStatus",
      "baseName": "kubeAccessStatus",
      "type": "KubeAccessStatus",
      "format": ""
    },
    {
      "name": "encryptionEnabled",
      "baseName": "encryptionEnabled",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return EksCluster.attributeTypeMap;
  }

  public constructor() {
  }
}



