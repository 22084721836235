/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Query status. If completed, the query results can be retrieved. 
*/
export enum QueryDBStatus {
  QUERY_STATUS_UNSPECIFIED = 'UNSPECIFIED',
  QUERY_IN_PROGRESS = 'IN_PROGRESS',
  QUERY_COMPLETED = 'COMPLETED',
  QUERY_CANCELED = 'CANCELED',
  QUERY_FAILED = 'FAILED'
}
