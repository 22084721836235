/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AzureManagementGroup } from '../models/AzureManagementGroup';
import { AzureSubscription } from '../models/AzureSubscription';
import { AzureTenant } from '../models/AzureTenant';

export class AzureListSubscriptionsResponse {
  'tenants'?: Array<AzureTenant>;
  'managementGroups': Array<AzureManagementGroup>;
  'subscriptions'?: Array<AzureSubscription>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "tenants",
      "baseName": "tenants",
      "type": "Array<AzureTenant>",
      "format": ""
    },
    {
      "name": "managementGroups",
      "baseName": "managementGroups",
      "type": "Array<AzureManagementGroup>",
      "format": ""
    },
    {
      "name": "subscriptions",
      "baseName": "subscriptions",
      "type": "Array<AzureSubscription>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AzureListSubscriptionsResponse.attributeTypeMap;
  }

  public constructor() {
  }
}

