/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { InventorySnapshotVolume } from '../models/InventorySnapshotVolume';

/**
* Properties of the original AWS EKS namespace instance at the time the snapshot was taken. Present only when `resourceType` is set to `AWS_EKS_NAMESPACE`. 
*/
export class AwsEksNamespaceSnapshotProperties {
  'volumes'?: Array<InventorySnapshotVolume>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "volumes",
      "baseName": "volumes",
      "type": "Array<InventorySnapshotVolume>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AwsEksNamespaceSnapshotProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

