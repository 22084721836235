/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AwsRdsResourceStorageSettings } from '../models/AwsRdsResourceStorageSettings';

/**
* Properties of the original AWS RDS instance. Present only when `resourceType` is set to `AWS_RDS`. 
*/
export class AwsRdsResource {
  /**
  * Total database storage size, in bytes.
  */
  'sizeBytes': number;
  /**
  * Database cluster ID.
  */
  'clusterId': string;
  /**
  * Database engine.
  */
  'engine': string;
  /**
  * Database engine version.
  */
  'engineVersion': string;
  'storageSettings'?: AwsRdsResourceStorageSettings;
  /**
  * Database name.
  */
  'databaseName': string;
  /**
  * Database instance class.
  */
  'instanceClass': string;
  /**
  * Whether the database is multi-AZ.
  */
  'isMultiAZ': boolean;
  /**
  * Whether the database is publicly accessible.
  */
  'isPubliclyAccessible': boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "sizeBytes",
      "baseName": "sizeBytes",
      "type": "number",
      "format": "int64"
    },
    {
      "name": "clusterId",
      "baseName": "clusterId",
      "type": "string",
      "format": ""
    },
    {
      "name": "engine",
      "baseName": "engine",
      "type": "string",
      "format": ""
    },
    {
      "name": "engineVersion",
      "baseName": "engineVersion",
      "type": "string",
      "format": ""
    },
    {
      "name": "storageSettings",
      "baseName": "storageSettings",
      "type": "AwsRdsResourceStorageSettings",
      "format": ""
    },
    {
      "name": "databaseName",
      "baseName": "databaseName",
      "type": "string",
      "format": ""
    },
    {
      "name": "instanceClass",
      "baseName": "instanceClass",
      "type": "string",
      "format": ""
    },
    {
      "name": "isMultiAZ",
      "baseName": "isMultiAZ",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "isPubliclyAccessible",
      "baseName": "isPubliclyAccessible",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AwsRdsResource.attributeTypeMap;
  }

  public constructor() {
  }
}

