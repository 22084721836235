/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AzureManagementGroup } from '../models/AzureManagementGroup';
import { AzureSubscription } from '../models/AzureSubscription';

export class AzureOnboardingRequest {
  /**
  * The account type - restore \\ source
  */
  'accountType': AzureOnboardingRequestAccountTypeEnum;
  /**
  * The tenant id
  */
  'tenantId': string;
  'managementGroups'?: Array<AzureManagementGroup>;
  'subscriptions'?: Array<AzureSubscription>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "accountType",
      "baseName": "accountType",
      "type": "AzureOnboardingRequestAccountTypeEnum",
      "format": ""
    },
    {
      "name": "tenantId",
      "baseName": "tenantId",
      "type": "string",
      "format": ""
    },
    {
      "name": "managementGroups",
      "baseName": "managementGroups",
      "type": "Array<AzureManagementGroup>",
      "format": ""
    },
    {
      "name": "subscriptions",
      "baseName": "subscriptions",
      "type": "Array<AzureSubscription>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AzureOnboardingRequest.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum AzureOnboardingRequestAccountTypeEnum {
  Restore = 'restore',
  Source = 'source'
}

