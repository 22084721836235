import { Provider } from '@repo/api-gw-sdk';
import type { NextRequest } from 'next/server';

import { parseJson } from '@/utils/string';

export const providerAndMarketplaceTokenCookieName = 'mptoken';
export const sessionTokenCookieName = 'next-auth.session-token';
export const securedSessionTokenCookieName = `__Secure-${sessionTokenCookieName}`;
export const globalSettingsAccessCookieName = 'eon-global-settings-access';
export const demoSettingsCookieName = 'eon-demo-settings';
export const getGlobalSettingsAccessCookieExpiration = () =>
  new Date(new Date().getTime() + 1000 * 60 * 60 * 2); // 2 hours
export const getDemoSettingsCookieExpiration = () =>
  new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30); // 30 days

export interface Environment {
  isDemo: boolean;
  isDev: boolean;
  clientApiBaseUrl: string;
  exposedTestingGlobals: boolean;
  authToken: string;
  serviceAccountId: string | null;
}

export interface DemoSettings {
  [Provider.Aws]: boolean;
  [Provider.Azure]: boolean;
  [Provider.Gcp]: boolean;
  [Provider.MongoAtlas]: boolean;
  ransomware: boolean;
}

export const defaultDemoSettings: DemoSettings = {
  [Provider.Aws]: true,
  [Provider.Azure]: true,
  [Provider.Gcp]: true,
  [Provider.MongoAtlas]: true,
  ransomware: false,
};

export const defaultEnvironment = {
  isDemo: process.env.IS_DEMO === 'true',
  isDev: Boolean(
    process.env.NODE_ENV === 'development' ||
      process.env.APP_URL?.startsWith('http://localhost:')
  ),
  clientApiBaseUrl: process.env.CLIENT_API_BASE_URL ?? '',
  exposedTestingGlobals: process.env.EXPOSE_TESTING_GLOBALS === 'true',
  serviceAccountId: process.env.SERVICE_ACCOUNT_ID ?? null,
};

export const getDemoSettings = (req: NextRequest) => {
  const cookie = req.cookies.get(demoSettingsCookieName);
  if (cookie) {
    const demoSettings = parseJson<DemoSettings>(cookie.value);
    if (demoSettings) {
      return demoSettings;
    }
  }

  return defaultDemoSettings;
};
