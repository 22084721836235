/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Condition } from '../models/Condition';
import { CreatePolicyScheduleInput } from '../models/CreatePolicyScheduleInput';

export class CreatePolicyInput {
  /**
  * The name of the policy
  */
  'name': string;
  /**
  * Whether the policy is enabled
  */
  'enabled': boolean;
  'condition'?: Condition;
  'include': Array<string>;
  'exclude': Array<string>;
  /**
  * Whether the policy was defined using the basic UI
  */
  'basic'?: boolean;
  'schedules': Array<CreatePolicyScheduleInput>;
  /**
  * Whether the security scan is enabled
  */
  'isSecurityScanEnabled'?: boolean;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "enabled",
      "baseName": "enabled",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "condition",
      "baseName": "condition",
      "type": "Condition",
      "format": ""
    },
    {
      "name": "include",
      "baseName": "include",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "exclude",
      "baseName": "exclude",
      "type": "Array<string>",
      "format": ""
    },
    {
      "name": "basic",
      "baseName": "basic",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "schedules",
      "baseName": "schedules",
      "type": "Array<CreatePolicyScheduleInput>",
      "format": ""
    },
    {
      "name": "isSecurityScanEnabled",
      "baseName": "isSecurityScanEnabled",
      "type": "boolean",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return CreatePolicyInput.attributeTypeMap;
  }

  public constructor() {
  }
}

