/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { VolumeSettings } from '../models/VolumeSettings';

export class RestoreInstanceVolumeInput {
  /**
  * The volume provider ID to restore
  */
  'providerVolumeId': string;
  /**
  * Optional description of the output volume
  */
  'description'?: string;
  /**
  * Optional tags to apply to the output volume
  */
  'tags': { [key: string]: string; };
  /**
  * Optional encryption key ID to use for the output volume
  */
  'volumeEncryptionKeyId'?: string;
  /**
  * Optional encryption key ID to use for the restore process
  */
  'encryptionKeyId'?: string;
  'volumeSettings': VolumeSettings;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "providerVolumeId",
      "baseName": "providerVolumeId",
      "type": "string",
      "format": ""
    },
    {
      "name": "description",
      "baseName": "description",
      "type": "string",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "volumeEncryptionKeyId",
      "baseName": "volumeEncryptionKeyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "encryptionKeyId",
      "baseName": "encryptionKeyId",
      "type": "string",
      "format": ""
    },
    {
      "name": "volumeSettings",
      "baseName": "volumeSettings",
      "type": "VolumeSettings",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return RestoreInstanceVolumeInput.attributeTypeMap;
  }

  public constructor() {
  }
}

