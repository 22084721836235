/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class SecurityScan {
  /**
  * Eon-assigned snapshot ID.
  */
  'snapshotId': string;
  /**
  * bottom line conclusion of the security scan.
  */
  'conclusion': SecurityScanConclusionEnum;
  /**
  * Number of suspicious evidences backing the conclusion
  */
  'totalEvidenceCount': number;
  /**
  * Date and time the scan was ended.
  */
  'createdTime': Date;
  /**
  * Summary of evidence details for the security scan. Each object represents an event with its count and up to three example file paths.
  */
  'summaryJson': string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "snapshotId",
      "baseName": "snapshotId",
      "type": "string",
      "format": ""
    },
    {
      "name": "conclusion",
      "baseName": "conclusion",
      "type": "SecurityScanConclusionEnum",
      "format": ""
    },
    {
      "name": "totalEvidenceCount",
      "baseName": "totalEvidenceCount",
      "type": "number",
      "format": ""
    },
    {
      "name": "createdTime",
      "baseName": "createdTime",
      "type": "Date",
      "format": "date-time"
    },
    {
      "name": "summaryJson",
      "baseName": "summaryJson",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return SecurityScan.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum SecurityScanConclusionEnum {
  Clean = 'CLEAN',
  HighChanceForRansomware = 'HIGH_CHANCE_FOR_RANSOMWARE'
}

