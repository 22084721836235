/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { NotificationPolicyConfig } from '../models/NotificationPolicyConfig';
import { NotificationType } from '../models/NotificationType';

export class NotificationPolicy {
  /**
  * The id of the vault
  */
  'id': string;
  /**
  * The project ID
  */
  'projectId': string;
  /**
  * The name of the target
  */
  'name': string;
  /**
  * The enabled status of the target
  */
  'enabled': boolean;
  /**
  * The type of the target (e.g. SNS, Slack, etc.)
  */
  'type': NotificationPolicyTypeEnum;
  'config': NotificationPolicyConfig;
  /**
  * The notification types of the target
  */
  'notificationTypes': Array<NotificationType>;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "id",
      "baseName": "id",
      "type": "string",
      "format": ""
    },
    {
      "name": "projectId",
      "baseName": "projectId",
      "type": "string",
      "format": ""
    },
    {
      "name": "name",
      "baseName": "name",
      "type": "string",
      "format": ""
    },
    {
      "name": "enabled",
      "baseName": "enabled",
      "type": "boolean",
      "format": ""
    },
    {
      "name": "type",
      "baseName": "type",
      "type": "NotificationPolicyTypeEnum",
      "format": ""
    },
    {
      "name": "config",
      "baseName": "config",
      "type": "NotificationPolicyConfig",
      "format": ""
    },
    {
      "name": "notificationTypes",
      "baseName": "notificationTypes",
      "type": "Array<NotificationType>",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return NotificationPolicy.attributeTypeMap;
  }

  public constructor() {
  }
}


export enum NotificationPolicyTypeEnum {
  Slack = 'Slack',
  Email = 'Email',
  Sns = 'SNS',
  Splunk = 'SPLUNK'
}

