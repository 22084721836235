/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* The access mode of the kubernetes persistent volume.
*/
export enum KubernetesAccessMode {
  ReadWriteOnce = 'READ_WRITE_ONCE',
  ReadWriteMany = 'READ_WRITE_MANY',
  ReadOnlyMany = 'READ_ONLY_MANY',
  ReadWriteOncePod = 'READ_WRITE_ONCE_POD',
  KubernetesAccessModeUnspecified = 'KUBERNETES_ACCESS_MODE_UNSPECIFIED'
}
