/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* Indicates the level of \'kubectl\' access this service has to the cluster. For example, FULL_ACCESS means the service can perform all required operations, while INSUFFICIENT_PERMISSIONS or NO_NETWORK_ACCESS indicates partial or no ability to run kubectl commands. 
*/
export enum KubeAccessStatus {
  KUBE_ACCESS_STATUS_INSUFFICIENT_PERMISSIONS = 'KUBE_ACCESS_STATUS_INSUFFICIENT_PERMISSIONS',
  KUBE_ACCESS_STATUS_FULL_ACCESS = 'KUBE_ACCESS_STATUS_FULL_ACCESS',
  KUBE_ACCESS_STATUS_PARTIAL_ACCESS = 'KUBE_ACCESS_STATUS_PARTIAL_ACCESS',
  KUBE_ACCESS_STATUS_NO_NETWORK_ACCESS = 'KUBE_ACCESS_STATUS_NO_NETWORK_ACCESS',
  KUBE_ACCESS_STATUS_UNSPECIFIED = 'KUBE_ACCESS_STATUS_UNSPECIFIED'
}
