/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


/**
* The type of the notification (e.g. BACKUP_JOB_FAILED, RESTORE_JOB_SUCCEEDED, etc.)
*/
export enum NotificationType {
  NotificationTypeUnknown = 'NOTIFICATION_TYPE_UNKNOWN',
  BackupJobFailed = 'BACKUP_JOB_FAILED',
  RestoreJobSucceeded = 'RESTORE_JOB_SUCCEEDED',
  RestoreJobFailed = 'RESTORE_JOB_FAILED',
  BackupPolicyApplied = 'BACKUP_POLICY_APPLIED',
  BackupPolicyUnapplied = 'BACKUP_POLICY_UNAPPLIED',
  BackupPolicyCreated = 'BACKUP_POLICY_CREATED',
  BackupPolicyUpdated = 'BACKUP_POLICY_UPDATED',
  BackupPolicyDeleted = 'BACKUP_POLICY_DELETED',
  BackupVaultCreated = 'BACKUP_VAULT_CREATED',
  BackupVaultDeleted = 'BACKUP_VAULT_DELETED',
  CloudAccountCreated = 'CLOUD_ACCOUNT_CREATED',
  CloudAccountUpdated = 'CLOUD_ACCOUNT_UPDATED',
  CloudAccountDeleted = 'CLOUD_ACCOUNT_DELETED',
  RansomwareDetected = 'RANSOMWARE_DETECTED'
}
