/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { Provider } from '../models/Provider';
import { ResourceSnapshotProperties } from '../models/ResourceSnapshotProperties';
import { ResourceType } from '../models/ResourceType';

/**
* Details about the backed-up resource at the time the snapshot was taken.
*/
export class ResourceSnapshot {
  'properties'?: ResourceSnapshotProperties | null;
  /**
  * Resource tags as key-value pairs. Both keys and values are strings. If a tag is a key with no value, the value is presented as an empty string. 
  */
  'tags'?: { [key: string]: string; };
  'cloudProvider'?: Provider;
  'resourceType'?: ResourceType;
  /**
  * Cloud-provider-assigned resource ID.
  */
  'providerResourceId'?: string;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "properties",
      "baseName": "properties",
      "type": "ResourceSnapshotProperties",
      "format": ""
    },
    {
      "name": "tags",
      "baseName": "tags",
      "type": "{ [key: string]: string; }",
      "format": ""
    },
    {
      "name": "cloudProvider",
      "baseName": "cloudProvider",
      "type": "Provider",
      "format": ""
    },
    {
      "name": "resourceType",
      "baseName": "resourceType",
      "type": "ResourceType",
      "format": ""
    },
    {
      "name": "providerResourceId",
      "baseName": "providerResourceId",
      "type": "string",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ResourceSnapshot.attributeTypeMap;
  }

  public constructor() {
  }
}



