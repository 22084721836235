/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */


export class AwsRdsResourceStorageSettings {
  /**
  * Database storage type.
  */
  'type': string;
  /**
  * Database storage IOPS.
  */
  'iops': number;
  /**
  * Database storage throughput.
  */
  'throughput': number;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "type",
      "baseName": "type",
      "type": "string",
      "format": ""
    },
    {
      "name": "iops",
      "baseName": "iops",
      "type": "number",
      "format": ""
    },
    {
      "name": "throughput",
      "baseName": "throughput",
      "type": "number",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return AwsRdsResourceStorageSettings.attributeTypeMap;
  }

  public constructor() {
  }
}

