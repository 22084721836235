/**
 * Eon API
 * The eon.io service API
 *
 * AUTOGENERATED: Do not edit the class manually.
 * Instead edit the .mustache file and run `node --run gen-openapi`
 */

import { AwsEc2SnapshotProperties } from '../models/AwsEc2SnapshotProperties';
import { AwsEksNamespaceSnapshotProperties } from '../models/AwsEksNamespaceSnapshotProperties';
import { AzureVmSnapshotProperties } from '../models/AzureVmSnapshotProperties';

/**
* Properties of the original resource at the time the snapshot was taken. The properties present in this object change depending on the value set in `resourceType`. 
*/
export class ResourceSnapshotProperties {
  'awsEc2'?: AwsEc2SnapshotProperties | null;
  'azureVm'?: AzureVmSnapshotProperties | null;
  'awsEksNamespace'?: AwsEksNamespaceSnapshotProperties | null;

  static readonly discriminator: string | undefined = undefined;

  static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
    {
      "name": "awsEc2",
      "baseName": "awsEc2",
      "type": "AwsEc2SnapshotProperties",
      "format": ""
    },
    {
      "name": "azureVm",
      "baseName": "azureVm",
      "type": "AzureVmSnapshotProperties",
      "format": ""
    },
    {
      "name": "awsEksNamespace",
      "baseName": "awsEksNamespace",
      "type": "AwsEksNamespaceSnapshotProperties",
      "format": ""
    }  ];

  static getAttributeTypeMap() {
    return ResourceSnapshotProperties.attributeTypeMap;
  }

  public constructor() {
  }
}

